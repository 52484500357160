
<template>
  <div class="table">
    <div class="table-bar">
      <el-input placeholder="Search target..." v-model="searchInput" prefix-icon="search" class="search-input"
        style="width: 300px" />
      <el-pagination id="el-pagination" v-model:currentPage="currentPage" :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize" :pager-count="9" :page-count="pageCount" background :total="filterData.length"
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-table :data="
      filterData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    " stripe border style="width: 100%" @sort-change="handleSortChange">
      <el-table-column sortable="custom" prop="name" label="Target Name" width="400" />
      <el-table-column sortable prop="test_auc" label="Model AUC" width="150" />
      <el-table-column sortable="custom" prop="total_count" label="Training Data Size" width="200" />
      <el-table-column sortable prop="score" label="Predict Score" />
      <el-table-column label="" width="120" class="detail-column">
        <template #default="scope">
          <button class="detail-btn" size="small" @click="showDetail(scope.row)">
            Detail
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog :destroy-on-close="true" width="1200px" v-model="ifshow">
    <PredictDetail ref="DetailModal" :data="detailData"></PredictDetail>
  </el-dialog>
</template>

<script>
import predictdetail from "../components/predictdetail.vue";
import { activityApi } from "../api/index";

export default {
  props: ["data", "modename"],
  data() {
    return {
      currentPage: 1,
      modelname: "",
      pageSize: 10,
      ifshow: false,
      detailData: [],
      searchInput: "",
    };
  },

  computed: {
    resultData() {
      return this.data;
    },
    //根据页面大小计算总页数
    pageCount() {
      return Math.ceil(this.filterData.length / this.pageSize);
    },
    filterData() {
      let input = this.searchInput && this.searchInput.toLowerCase();
      if (input == "") return this.resultData;
      else {
        return this.resultData.filter((item) => {
          return item.name.toLowerCase().match(input);
        });
      }
    },
  },
  components: {
    PredictDetail: predictdetail,
  },
  methods: {
    showDetail(e) {
      this.modelname = e.name;
      activityApi
        .getDetailModelInfo({ mode: this.modename, model: this.modelname })
        .then((res) => {
          if (res.code == 200) {
            this.detailData = res.data;
            this.ifshow = true;
          } else if (res.code == 5001) {
            this.$store.commit("alertMsg", {
              msg: "Mode parameter error!",
              type: "error",
            });
          } else if (res.code == 404) {
            this.$store.commit("alertMsg", {
              msg: "No model found!",
              type: "error",
            });
          }
        });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // 处理页面下标改变
      this.currentPage = val;
    },

    /**默认的排序只针对当前页，所以要重写处理分页后的表格排序
     * 1. String： 按字典序
     * 2. Number： 按数指大小
     */
    handleSortChange(target) {
      if (target.prop == "name") {
        // 实际数据中只有targetName为String，所以单独处理
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] < b[target.prop] ? -1 : 1;
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return a[target.prop] > b[target.prop] ? -1 : 1;
          });
        }
      } else {
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] - b[target.prop];
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return b[target.prop] - a[target.prop];
          });
        }
      }
    },
  },
};
</script>

<style>
.table {
  width: 1100px;
}

.table-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.search-input {
  font-size: 15px;
  padding-left: 8px;
}

#el-pagination .el-pagination__total,
#el-pagination .el-pagination__jump {
  font-size: 15px;
  color: white;
  /* font-weight: li */
}

#el-pagination .el-pager .number.active {
  background: var(--color2-dark);
}

#el-pagination .el-pager .number:hover,
#el-pagination .btn-prev.hover,
#el-pagination .btn-next.hover {
  background: var(--color2-light);
  color: var(--color1-dark);
}

.el-table__header-wrapper .el-table__cell {
  font-size: 12pt;
  color: var(--color1-dark);
}

.el-table__body .el-table__row,
.el-table__body .el-table__row:hover {
  background: var(--color2-light);
  font-size: 12pt;
}

.el-table .cell {
  text-align: center;
}

.detail-btn {
  font-size: 12pt;
  border-radius: 5px;
  background: none;
  outline: none;
  padding: 5px;
  border: 1px solid var(--color2-dark);
  cursor: pointer;
}

.detail-btn:hover {
  background: var(--color2-light);
}
</style>