<template>
  <div class="result">
    <div class="molecule">
      <h3>Molecular Information</h3>
      <div class="description">
        <div class="molecule-img" @click="isShowMoleculeName = !isShowMoleculeName">
          <img id="smilesimg" :src="moleculeImg" alt="An image of a modecule" />
          <figcaption>Molecular fomula</figcaption>
        </div>
        <div class="molecule-description">
          <div class="descrip-item" v-for="item in descriptions" :key="item.name">
            <div class="molecule-key">{{ item.key }}</div>
            <div class="molecule-value">{{ item.value }}</div>
            <div class="molecule-detail">
              <el-icon class="icon">
                <info-filled />
                <p>{{ item.detail }}</p>
                <div class="pointer"></div>
              </el-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="molecule-name" v-show="isShowMoleculeName">
        {{ formula }}
      </div>
    </div>
    <div class="kongban-section">
      <div class="color-type">
        <div class="color-item">
          <div class="color-bar"></div>
          <div class="color-score">0.0-0.4</div>
        </div>
        <div class="color-item">
          <div class="color-bar"></div>
          <div class="color-score">0.4-0.7</div>
        </div>
        <div class="color-item">
          <div class="color-bar"></div>
          <div class="color-score">0.7-1.0</div>
        </div>
      </div>
      <KongBan class="kongban" :data="kongbanData"></KongBan>
      <div class="selector" v-for="(item, type) in category" :key="type">
        <div class="menu-title" @click="isShowMenu = !isShowMenu">
          <p>{{ type }}</p>
          <div :class="['el-icon', isShowMenu ? 'show' : '']">
            <el-icon class="icon">
              <caret-bottom />
            </el-icon>
          </div>
        </div>
        <div :class="['menu-items', isShowMenu ? '' : 'not-show']">
          <div :class="['menu-item', selectItem == 'All' ? 'selected' : '']" @click="updateKongban('All')">
            <p>&nbsp;All</p>
            <el-icon class="el-icon">
              <caret-left />
            </el-icon>
          </div>
          <div :class="['menu-item', selectItem == key ? 'selected' : '']" v-for="(value, key) in item" :key="key"
            @click="updateKongban(key)">
            <p>&nbsp;{{ key }}</p>
            <el-icon>
              <caret-left />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <ResultTable :data="resultData" :modename="mode"></ResultTable>
  </div>
</template>

<script>
import kongban from "../components/kongban.vue";
import resultTable from "../components/resultTable.vue";
import { activityApi } from "../api/index";

export default {
  props: ["formula", "score", "category", "mode"],
  components: {
    KongBan: kongban,
    ResultTable: resultTable,
  },
  watch: {
    formula: function (oldVal, newVal) {
      console.log(oldVal, newVal);
      this.getData();
    },
  },
  data() {
    return {
      moleculeImg: null,
      isShowMoleculeName: false,
      isShowMenu: true,
      modelname: '',
      selectItem: "All",
      resultData: [],
      smilesInfo: {},
      modelInfo: {},
      currentrowinfo: {},
      kongbanData: {},
      descriptions: [
        {
          key: "Molecular Weight",
          value: "",
          detail: "Contain Hydrogen atoms",
        },
        { key: "nHD", value: "", detail: "Number of hydrogen bond donors" },
        { key: "nHA", value: "", detail: "Number of hydrogen bond acceptors" },
        {
          key: "LogP",
          value: "",
          detail: "Log of the octanol/water partition coefficient",
        },
        { key: "nRot", value: "", detail: "Number of rotatable bonds" },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取分子信息
    getSmileInfo() {
      return new Promise((resolve, reject) => {
        activityApi
          .getSmileInfo({ smiles: this.formula }) //先获取分子信息 (直接输入分子式)
          .then((res) => {
            if (res.code == 200) {
              console.log("获取分子信息", res.data);
              resolve(res.data);
            } else {
              reject(res.msg);
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
            reject(err);
          });
      });
    },
    // 获取模型信息
    getModelInfo() {
      return new Promise((resolve, reject) => {
        activityApi
          .getModelInfo({ mode: this.mode })
          .then((res) => {
            //获取成功后进一步 获取模型的信息
            if (res.code == 200) {
              console.log("获取模型信息", res.data);
              resolve(res.data);
            } else {
              reject(res.msg);
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
            reject(err);
          });
      });
    },
    // 获取预测结果
    getData() {
      Promise.all([this.getSmileInfo(), this.getModelInfo()])
        .then((res) => {
          console.log("promise all", res);
          this.smilesInfo = res[0][this.formula];
          this.modelInfo = res[1];
          this.moleculeImg = "data:image/png;base64," + this.smilesInfo["img"];
          this.descriptions[0].value = this.smilesInfo["mw"];
          this.descriptions[1].value = this.smilesInfo["hbd"];
          this.descriptions[2].value = this.smilesInfo["hba"];
          this.descriptions[3].value = this.smilesInfo["logp"];
          this.descriptions[4].value = this.smilesInfo["nrot"];
          this.resultData = this.modelInfo;
          for (var i = 0; i < this.resultData.length; i++) {
            this.resultData[i].score =
              this.score["All"][this.resultData[i].name];
          }
          this.kongbanData = this.score["All"];
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    updateKongban(key) {
      this.selectItem = key;
      this.kongbanData = this.score[key];
    },
  },
  updated() {
    // this.getData();
  },
  computed: {},
};
</script>


<style scoped>
.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.molecule {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  border-radius: 10px;
  background: white;
}

.molecule h3 {
  box-sizing: border-box;
  text-align: start;
  font-weight: lighter;
  width: 100%;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.molecule .description {
  display: flex;
}

.molecule-img {
  box-sizing: border-box;
  width: 480px;
  height: 250px;
  padding: 12px;
  position: relative;
  /* display: flex; */
  cursor: pointer;
}

.molecule-img figcaption {
  position: absolute;
  color: rgb(183, 183, 185);
  right: 12px;
  bottom: 12px;
}

.molecule img {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  object-fit: contain;
}

.molecule-name {
  box-sizing: border-box;
  text-align: start;
  font-weight: lighter;
  width: 100%;
  padding: 10px;
  font-size: 15pt;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: scroll;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.molecule-description {
  display: flex;
  width: 600px;
  flex-direction: column;
}

.descrip-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  text-align: center;
}

.descrip-item:nth-child(2n) {
  background: var(--color2-light);
}

.molecule-key {
  flex: 1;
}

.molecule-value {
  flex: 1;
}

.molecule-detail {
  flex: 1;
  position: relative;
  color: var(--color2-dark);
}

.molecule-detail .icon p {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  white-space: nowrap;
  display: none;
  padding: 5px;
  font-size: 15px;
  font-style: normal;
}

.pointer {
  display: none;
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.8);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-left: 20px solid rgba(0, 0, 0, 0);
  border-right: 20px solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.molecule-detail .icon {
  font-size: 20px;
  cursor: pointer;
}

.molecule-detail .icon:hover p,
.molecule-detail .icon:hover .pointer {
  display: block;
}

.kongban-section {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 380px;
  margin: 150px 0;
  position: relative;
}

.color-item {
  display: flex;
  position: relative;
}

.color-bar {
  width: 20px;
  height: 80px;
}

.color-item:nth-child(1) {
  background: linear-gradient(to bottom, rgb(182, 41, 34), rgb(210, 69, 54));
}

.color-item:nth-child(2) {
  background: linear-gradient(to bottom, rgb(226, 131, 55), rgb(239, 182, 77));
}

.color-item:nth-child(3) {
  background: linear-gradient(to bottom, rgb(70, 150, 80), rgb(106, 221, 128));
}

.color-score {
  top: 30%;
  left: 150%;
  position: absolute;
  text-align: center;
  color: white;
  font-size: 15px;
  line-height: 100%;
  white-space: nowrap;
}

.kongban {
  flex: 1;
  transform-style: preserve-3d;
}

.selector {
  max-height: 100%;
  width: 160px;
}

.menu-title {
  border-radius: 4px;
  height: 40px;
  font-size: 18pt;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
  cursor: pointer;
}

.menu-title .el-icon {
  position: relative;
  font-size: 22px;
  transition: 300ms ease-in;
  color: var(--color1-dark);
}

.menu-title .el-icon.show {
  transform: rotate(180deg);
}

.menu-items {
  height: 100%;
  background: white;
  overflow: scroll;
  padding: 0 15px;
  margin: 1px 0;
  transition: 300ms ease-in-out;
}

.menu-items.not-show {
  height: 0;
}

.menu-item {
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color2-dark);
  cursor: pointer;
}

.menu-item:hover {
  background: var(--color2-light);
}

.menu-item .el-icon {
  display: none;
}

.menu-item.selected .el-icon {
  font-size: 20px;
  color: var(--color1-light);
  display: inline-block;
}
</style>
